.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: var(--overlay-popover);
  background-color: var(--gray6_100);
  border: 1px solid var(--gray6_100);
  border-radius: 50%;
  opacity: 0.95;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
}

.button:not(:last-child) {
  margin-bottom: 15px;
}

.button:active {
  transform: scale(0.98);
}
