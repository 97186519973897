.container {
  padding: 20px 16px 40px;
}

.name {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
}

.address {
  margin-top: 4px;
  font-size: 14px;
  line-height: 1.29;
}

.phone {
  margin-top: 20px;
  padding: 9px 10px;
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 42px;
}

@media (min-width: 641px) {
  .container {
    padding: 0 24px 24px;
  }
}
