.container {
  margin: 16px 0 20px;
  height: 300px;
}

.map-preview {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: center no-repeat;
  background-size: cover;
}

.map-preview-pin {
  position: relative;
  bottom: 20px;
}

.map-container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.map {
  position: absolute;
  inset: 0;
}

.controls-container {
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.header {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  padding: 15px 50px 15px 20px;
  background-color: var(--black_60);
}

.header-title {
  margin: 0;
  font-size: 16px;
  text-align: center;
  color: var(--decorative-theme-white);
}

.close-button {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  background: transparent;
  border: 0;
}
